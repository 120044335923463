<template>
  <div
    v-click-outside="hideDropdown"
    class="relative inline-block text-left"
  >
    <div>
      <slot
        name="dropdownToggleBtn"
        v-bind="{ handleDropdownToggle }"
      >
        <base-button
          id="options-menu"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          aria-haspopup="true"
          aria-expanded="true"
          @click="handleDropdownToggle()"
        >
          <template #default>
            {{ dropdownBtnText }}
            <img
              :src="require('@/assets/images/icons/arrowDown.svg')"
              class="-mr-1 ml-2 h-5 w-5"
            >
          </template>
        </base-button>
      </slot>
    </div>
    <transition
      name="dropdown-transition"
      enter-from-class="transform opacity-0 scale-95"
      enter-active-class="transition ease-out duration-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-from-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="toggleDropdown"
        class="origin-top-right absolute right-0"
        :class="dropdownClass"
      >
        <div
          class="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <slot
            name="dropdownContent"
            v-bind="{ selectOption, handleDropdownToggle }"
          >
            <ul class="list-none">
              <li
                v-for="(dropdownOption, dIndex) in dropdownOptions"
                :key="'dropdownOption' + dIndex"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                role="menuitem"
                @click="selectOption(dropdownOption)"
              >
                {{ dropdownOption }}
              </li>
            </ul>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import useToggle from '@/hooks/toggle.js';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'BaseDropdown',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    props: {
        dropdownOptions: {
            type: Array,
            default: () => [],
            description: 'Array of options in dropdown'
        },
        dropdownBtnText: {
            type: String,
            default: '',
            description: 'Text for dropdown button'
        },
        dropdownClass: {
            type: [String, Object, Array],
            default: 'mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10',
            description: 'classes applied to dropdown box'
        }
    },

    emits: [
        'selected'
    ],

    setup (props, { emit }) {
        // toggle dropdown logic
        const { toggle: toggleDropdown, handleToggle: handleDropdownToggle } = useToggle();
        const hideDropdown = () => {
            toggleDropdown.value = false;
        };
        const selectOption = (option) => {
            emit('selected', option);
            hideDropdown();
        };
        return {
            toggleDropdown,
            handleDropdownToggle,
            hideDropdown,
            selectOption
        };
    }
};
</script>
