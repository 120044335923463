import { ref } from 'vue';

export default () => {
    const toggle = ref(false);
    const handleToggle = () => {
        toggle.value = !toggle.value;
    };
    return {
        toggle,
        handleToggle
    };
};
